import * as React from "react"

const SigSciIndexPage = () => {
  return (<></>)
}

export default SigSciIndexPage

export const Head = (props) => {
  return <></>
}
